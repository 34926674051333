<div class="mx-auto {{ !isPrescreenEnabled() ? 'max-w-7xl' : 'max-w-5xl' }} p-2 pt-8 md:px-6 lg:pt-14">
  <div
    class="bg-primary-950 mb-14 flex flex-col justify-between rounded-3xl p-5 md:p-8 lg:justify-end lg:p-9"
    [ngClass]="isPrescreenEnabled() ? 'gap-8 py-8 lg:flex-row' : 'lg:flex-row-reverse'"
  >
    @if (!isPrescreenEnabled()) {
      <div class="lg:hidden">
        <ng-container *ngTemplateOutlet="mainTitleDeprecated" />
      </div>

      <ng-container *ngTemplateOutlet="searchContainerTemp" />

      <div class="lg:mr-20 lg:pt-6">
        <div class="hidden lg:block" data-role="desktop-home-title">
          <ng-container *ngTemplateOutlet="mainTitleDeprecated" />
        </div>

        @for (iconName of icons; track iconName) {
          <div class="mb-10 sm:mb-4 md:flex">
            <div class="bg-primary-200 mb-3 mr-6 flex h-16 w-16 min-w-16 items-center justify-center rounded-full md:mb-0">
              <myt-icon [name]="iconName" [size]="iconSize.MediumSmall" />
            </div>
            <div
              class="font-gt-walsheim block w-full items-center text-xl font-light tracking-tight text-white md:flex md:w-auto lg:items-start"
            >
              {{ 'HOME.BULLET_POINTS_DEPRECATED.' + $index | translate }}
            </div>
          </div>
        }
      </div>
    } @else {
      <div class="text-neutral-200 lg:pt-6">
        <div class="mb-6" data-role="desktop-home-title">
          <h1 class="!text-4.5xl !mb-0 block text-balance !leading-none !tracking-normal text-white md:!text-5xl">
            <span class="font-normal">{{ 'HOME.TITLE.0' | translate }} </span>
            <span class="text-primary-300 font-semibold">{{ 'HOME.TITLE.1' | translate }} </span>
          </h1>
        </div>

        <div class="mb-6 text-xl">
          {{ 'HOME.DESC' | translate }}
        </div>
        <myt-expansion-panel class="w-full" [panelType]="'primary'" [noHover]="true">
          <label expansionPanelTitle class="text-lg font-medium">{{ 'HOME.BULLET_POINTS.0' | translate }}</label>
          <div expansionPanelContent class="text-regular text-neutral-150 font-normal leading-5 tracking-normal">
            <label>{{ 'HOME.BULLET_POINTS.1' | translate }}</label>
            <ul class="mb-5 ml-5 mt-2">
              <li><div [innerHTML]="'HOME.BULLET_POINTS.2' | translate"></div></li>
              <li><div [innerHTML]="'HOME.BULLET_POINTS.3' | translate"></div></li>
            </ul>
            <span class="mb-4 block">
              {{ 'HOME.BULLET_POINTS.4' | translate }}
            </span>
            <span class="block">{{ 'HOME.BULLET_POINTS.5' | translate }}</span>
          </div>
        </myt-expansion-panel>
      </div>

      <ng-container *ngTemplateOutlet="searchContainerTemp" />
    }
  </div>

  <div class="mb-14 flex flex-col gap-5 md:gap-10 lg:flex-row lg:gap-14">
    @if (sharedAuth.$getUserType() === userType.hcp) {
      <div class="box-border flex w-full rounded-3xl border border-solid border-neutral-200 bg-white p-6 lg:order-2 lg:w-6/12">
        <div class="max-h-80 w-full">
          <myt-ai-prescreening-history />
        </div>
      </div>
    }

    @for (card of cards; track card.imagePath) {
      @if (!card.hideFor.includes(sharedAuth.$getUserType())) {
        <div
          class="box-border w-full rounded-3xl border border-solid border-neutral-200 bg-white bg-contain bg-right-bottom bg-no-repeat md:flex lg:w-6/12"
        >
          <div class="p-4 pb-0 md:w-9/12 md:p-8 md:pb-8 lg:w-full lg:pr-0">
            <div class="mb-6 text-3xl leading-none">
              <span class="font-gt-walsheim font-medium">{{ 'HOME.CARDS_TITLE' | translate }} </span>
              <span class="font-dm-serif-display font-normal italic">{{ 'HOME.CARDS.' + $index + '.TITLE' | translate }}</span>
            </div>

            <div class="font-gt-walsheim mb-6 text-base font-light">{{ 'HOME.CARDS.' + $index + '.TEXT' | translate }}</div>

            <myt-button [type]="buttonType.StrokedLink" linkTarget="_blank" [routeConfig]="{ href: card.link }">{{
              'HOME.LEARN_MORE' | translate
            }}</myt-button>
          </div>
          <div class="flex p-2 pb-0 pr-0 md:ml-auto md:mt-auto">
            <img
              src="{{ card.imagePath }}"
              alt="{{ 'HOME.CARDS.' + $index + '.TITLE' | translate }}"
              class="ml-auto mt-auto max-w-56 lg:max-w-full"
            />
          </div>
        </div>
      }
    }
  </div>
</div>

<ng-template #mainTitleDeprecated>
  <h1 class="!text-4.5xl !mb-16 block text-balance !leading-none !tracking-normal text-white md:!text-5xl">
    <span class="font-gt-walsheim font-light">{{ 'HOME.TITLE_DEPRECATED.0' | translate }} </span>
    <span class="font-dm-serif-display font-normal italic">{{ 'HOME.TITLE_DEPRECATED.1' | translate }} </span>
    <span class="font-gt-walsheim font-light">{{ 'HOME.TITLE_DEPRECATED.2' | translate }} </span>
    <span class="font-dm-serif-display font-normal italic">{{ 'HOME.TITLE_DEPRECATED.3' | translate }} </span>
    <span class="font-gt-walsheim font-light">{{ 'HOME.TITLE_DEPRECATED.4' | translate }} </span>
  </h1>
</ng-template>

<ng-template #searchContainerTemp>
  <div class="lg:mb-0">
    <myt-search-new
      (searchParamsChanges)="onSearchParamsChanges($event)"
      [enableTrialMatch]="isPrescreenEnabled()"
      class="lg:w-112 lg:min-w-112 box-border w-full rounded-lg px-6 pt-6"
    />
  </div>
</ng-template>
