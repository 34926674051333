import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';

import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateModuleConfig } from '@ngx-translate/core/dist/public-api';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${new Date().getTime()}`);
}

export const translationConfig: TranslateModuleConfig = {
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient],
  },
};

const translateInitializer = (translateService: TranslateService) => () => translateService.use(translateService.defaultLang);

export const translationInitializer: Provider = {
  provide: APP_INITIALIZER,
  useFactory: translateInitializer,
  deps: [TranslateService],
  multi: true,
};
