<div class="platform mat-typography relative {{ customCssClasses() }}" [ngClass]="{ 'h-screen': $fullScreenHeight() }">
  @if (headerState() !== headerStates.Hidden) {
    <header
      [ngClass]="{ 'sticky top-0': headerState() === headerStates.Sticky }"
      [mytToggleNavbarOnScroll]="headerState() === headerStates.Toggle"
    >
      <myt-navbar />
    </header>
  }
  <main class="flex h-full flex-grow flex-col">
    @if ($breadcrumbData()) {
      <div class="platform__breadcrumb">
        <myt-breadcrumb [breadcrumbs]="$breadcrumbData()" />
      </div>
    }
    <div class="platform__content h-full overflow-x-auto">
      <router-outlet />
    </div>
  </main>

  <footer>
    <myt-footer />
  </footer>
</div>
