<div class="mb-3 inline-flex items-center gap-2">
  <h3 class="!text-1.5xl !mb-0 font-semibold">{{ 'SEARCH.PRESCREENING_HISTORY.TITLE' | translate }}</h3>
  <div class="bg-primary-100 rounded-full p-1.5">
    <myt-icon name="ai" [size]="iconSize.ExtraSmall" class="text-primary-950" />
  </div>
</div>

@if (prescreeningHistory()?.loading) {
  <div class="flex h-full w-full items-center justify-center">
    <myt-spinner class="m-auto py-10" />
  </div>
} @else if (prescreeningHistory()?.error) {
  <myt-expansion-panel panelType="error" class="mb-3 block w-full" [panelOpenState]="true">
    <div expansionPanelTitle data-role="prescreening-history-error-state-title">
      <span class="text-regular font-normal"> {{ 'SEARCH.PRESCREENING_HISTORY.ERROR_TITLE' | translate }} </span>
    </div>
    <div expansionPanelContent>
      <div class="text-regular text-error-800 mb-2" data-role="prescreening-history-error-state-desc">
        {{ 'SEARCH.PRESCREENING_HISTORY.ERROR_DESC' | translate }}
      </div>
      <span
        class="text-error-800 hover:text-error-700 underline hover:cursor-pointer"
        role="button"
        (click)="retryFetch()"
        data-role="prescreening-history-retry-button"
        >{{ 'SEARCH.PRESCREENING_HISTORY.RELOAD_BUTTON' | translate }}
      </span>
    </div>
  </myt-expansion-panel>
} @else if (!prescreeningHistory()?.data?.length) {
  <myt-expansion-panel panelType="neutral" class="mb-3 block w-full" [panelOpenState]="true">
    <div expansionPanelTitle data-role="prescreening-history-empty-state-title">
      <span class="text-regular"> {{ 'SEARCH.PRESCREENING_HISTORY.EMPTY_TITLE' | translate }} </span>
    </div>
    <div expansionPanelContent>
      <div class="text-regular mb-2" data-role="prescreening-history-empty-state-desc">
        {{ 'SEARCH.PRESCREENING_HISTORY.EMPTY_DESC' | translate }}
      </div>
      <span class="text-neutral-300 underline" aria-disabled="true" role="button"
        >{{ 'SEARCH.PRESCREENING_HISTORY.VIEW_RESULTS_LINK' | translate }}
      </span>
    </div>
  </myt-expansion-panel>
} @else {
  <div class="h-full overflow-y-auto">
    @for (item of prescreeningHistory()?.data; track $index) {
      <myt-expansion-panel panelType="neutral" class="mb-3 block w-full">
        <div expansionPanelTitle>
          <span class="text-regular text-primary-950 font-normal">
            @if (item.patientId && item.patientId !== 'Anonymous') {
              <strong class="font-semibold">{{ 'SEARCH.PRESCREENING_HISTORY.REFERENCE_LABEL' | translate }}:</strong> {{ item.patientId }}
            } @else {
              {{ item.title }}
            }
          </span>
        </div>
        <div expansionPanelContent>
          <div class="text-regular mb-2 line-clamp-2" data-role="prescreening-history-list-item-desc">{{ item.profile }}</div>
          <a class="text-accent-600 underline" [routerLink]="['/search-results', 'pre-screening-v2', item.uuid]"
            >{{ 'SEARCH.PRESCREENING_HISTORY.VIEW_RESULTS_LINK' | translate }}
          </a>
        </div>
      </myt-expansion-panel>
    }
  </div>
}
