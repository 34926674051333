import { ChangeDetectionStrategy, Component, inject, Injector, runInInjectionContext } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { PrescreeningApiService } from '@my-tomorrows/api';
import { ExpansionPanelComponent, IconsComponent, IconSize, SpinnerComponent } from '@my-tomorrows/components';
import { toDataState } from '@my-tomorrows/shared-utils';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs';
import { PrescreeningHistoryItem } from '../../interfaces/pre-screening-history.interface';
import { mapPrescreeningHistoryResponse } from '../../utils/pre-screening-history/pre-screening-history.util';

@Component({
  selector: 'myt-ai-prescreening-history',
  standalone: true,
  imports: [ExpansionPanelComponent, TranslateModule, IconsComponent, RouterLink, SpinnerComponent],
  templateUrl: './ai-prescreening-history.component.html',
  styleUrl: './ai-prescreening-history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPrescreeningHistoryComponent {
  private readonly prescreeningApiService = inject(PrescreeningApiService);
  private readonly injector = inject(Injector);

  readonly iconSize = IconSize;
  prescreeningHistory = toSignal(this.fetchPrescreeningHistory());

  retryFetch() {
    runInInjectionContext(this.injector, () => {
      this.prescreeningHistory = toSignal(this.fetchPrescreeningHistory());
    });
  }

  private fetchPrescreeningHistory() {
    return toDataState<Array<PrescreeningHistoryItem & { title: string }>>(
      this.prescreeningApiService.getPrescreeningHistory({ limit: 1000, offset: 0 }).pipe(
        map((res) => mapPrescreeningHistoryResponse(res).details),
        map((list) =>
          list.map((item) => ({
            ...item,
            title: [item.condition, ...(item.country || []), item.otherTerms].filter((i) => i?.trim()).join(', '),
            profile: this.parseMedicalSummary(item.profile),
          })),
        ),
      ),
    );
  }

  private parseMedicalSummary(medicalSummary: string | undefined): string {
    let parsedMedicalSummary = '';

    try {
      parsedMedicalSummary = JSON.parse(medicalSummary || '');
    } catch {
      parsedMedicalSummary = medicalSummary || '';
    }

    return parsedMedicalSummary.replace(/(\/n)/g, '<br/>');
  }
}
