import { bootstrapApplication } from '@angular/platform-browser';

import { appConfig } from './app/app.config';

import { AppComponent } from './app/app.component';

import { initializeCookieScript } from './script-initializers/cookie-first-init';
import { initializePosthog } from './script-initializers/posthog-init';

initializeCookieScript();
initializePosthog();

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
