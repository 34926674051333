import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, computed, inject, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ButtonSize, ButtonType, ButtonsComponent, IconSize, IconsComponent, MenuComponent } from '@my-tomorrows/components';
import { ENVIRONMENT } from '@my-tomorrows/core-environment';
import { RedirectService, SharedAuthService, UserType } from '@my-tomorrows/shared-data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Component({
  selector: 'myt-navbar',
  standalone: true,
  imports: [NgTemplateOutlet, MenuComponent, RouterModule, TranslateModule, ButtonsComponent, IconsComponent, NgOptimizedImage],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  private readonly translateService = inject(TranslateService);
  private readonly authService = inject(SharedAuthService);
  private readonly router = inject(Router);
  private readonly redirectService = inject(RedirectService);
  readonly environment = inject(ENVIRONMENT);
  readonly buttonType = ButtonType;
  readonly buttonSize = ButtonSize;
  readonly iconSize = IconSize;
  readonly userType = UserType;
  readonly $mobileExpanded = signal<boolean>(false);
  readonly $navItems = computed(() => {
    const navItems = [
      {
        title: this.translateService.instant('PLATFORM.HEADER.SEARCH'),
        route: 'home',
        hide: [UserType.search, UserType.patient, UserType.siteManager].includes(this.authService.$getUser().userType),
      },
      {
        title: this.translateService.instant('PLATFORM.HEADER.REFERRAL'),
        route: 'referrals',
        hide: ![UserType.hcp].includes(this.authService.$getUser().userType),
      },
      {
        title: this.translateService.instant('PATIENT.DOCUMENTS.NAVBAR'),
        route: '/home/documents',
        hide: this.authService.$getUser().userType !== UserType.patient,
      },
      {
        title: this.translateService.instant('PLATFORM.HEADER.HCP_CLINICAL_REFERRALS'),
        route: 'hcp-clinical-referral',
        hide: !this.authService.$getUser().isCrtMember,
      },
      {
        title: this.translateService.instant('PLATFORM.HEADER.HCP_TRIALS'),
        route: 'my-trials',
        hide: !this.authService.$getUser().isCrtMember,
      },
      {
        title: this.translateService.instant('EAP.TITLE'),
        route: 'eap/tracker',
        hide: !(this.authService.$getUser().userType === UserType.siteManager),
      },
    ];

    //TODO: Remove this condition when the EAP program is ready
    if (!this.environment.production) {
      navItems.push({
        title: this.translateService.instant('EAP.PROGRAM_TITLE'),
        route: 'eap/programs',
        hide: !(this.authService.$getUser().userType === UserType.siteManager),
      });
    }

    return navItems;
  });

  readonly $userDropdownMenu = computed(() => {
    const userType = this.$user().userType;
    let menu;

    switch (userType) {
      case UserType.siteManager:
        menu = [{ label: this.translateService.instant('PLATFORM.HEADER.LOGOUT') }];
        break;
      case UserType.mytUser:
        menu = [{ label: this.translateService.instant('PLATFORM.HEADER.LOGOUT') }];
        break;
      default:
        menu = [
          {
            label: this.translateService.instant('PLATFORM.HEADER.SETTINGS'),
            link: 'settings/profile',
          },
          {
            label: this.translateService.instant('PLATFORM.HEADER.LOGOUT'),
          },
        ];
        break;
    }

    return menu;
  });

  readonly $user = computed(() => this.authService.$getUser());

  readonly $avatarIcon = computed<string>(() => {
    switch (this.$user().userType) {
      case UserType.hcp:
        return 'stethoscope';
      default:
        return 'person_add';
    }
  });

  onUserMenuItemSelect(selectedItem: string): void {
    this.$mobileExpanded.set(false);
    const logoutLabel = this.translateService.instant('PLATFORM.HEADER.LOGOUT');
    const logoutUrl = this.getLogoutUrl();
    if (logoutLabel === selectedItem) {
      this.authService
        .logout()
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigateByUrl(logoutUrl);
        });
    }
  }

  getLogoutUrl(): string {
    switch (this.$user().userType) {
      case UserType.patient:
        return '/login';
      default:
        return '/home';
    }
  }

  navigateTo(route: string): void {
    this.redirectService.navigate(route);
  }

  toggleNavbar(): void {
    this.$mobileExpanded.update((value) => !value);
  }
}
