import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { Breadcrumb } from '@my-tomorrows/components';
import { Environment } from '@my-tomorrows/core-environment';
import { RouteData, UserType } from '@my-tomorrows/shared-data';

export function getSearchRoutes(environment: Environment): Route[] {
  return [
    {
      path: '',
      loadComponent: () => import('./pages/search-results/search-results.component').then((c) => c.SearchResultsComponent),
      data: {
        [RouteData.TabTitle]: 'SEARCH.SEARCH_FOR',
      },
    },
    {
      path: 'exclusion-rules',
      loadComponent: () =>
        import('./pages/search-results-exclusion-rules/search-results-exclusion-rules.component').then(
          (c) => c.SearchResultsExclusionRulesComponent,
        ),
      data: {
        [RouteData.TabTitle]: 'SEARCH.EXCLUSION_RULES',
      },
    },
    {
      path: 'pre-screening/:searchRecordId', // Support this page for upcoming a couple of months.
      loadComponent: () =>
        import('./pages/pre-screening-results-deprecated/pre-screening-results.component').then(
          (c) => c.PreScreeningResultsDeprecatedComponent,
        ),
      data: {
        [RouteData.FullScreenHeight]: true,
        [RouteData.Breadcrumb]: [{ title: 'Search results', useBrowserHistory: true }, { title: 'Pre-screening results' }] as Breadcrumb[],
        [RouteData.Role]: [UserType.hcp],
      },
    },
    {
      path: 'pre-screening-v2/:searchRecordId',
      loadComponent: () =>
        import('./pages/pre-screening-results-demo/pre-screening-results.component').then((c) => c.PreScreeningResultsDemoComponent),
      data: {
        [RouteData.CustomCssClasses]: 'md:h-screen',
        [RouteData.Breadcrumb]: [{ title: 'Search results', useBrowserHistory: true }, { title: 'Pre-screening results' }] as Breadcrumb[],
        [RouteData.Role]: [UserType.hcp],
      },
    },
    {
      path: 'pre-screening-check',
      loadComponent: () => import('./pages/pre-screening-check/pre-screening-check.component').then((c) => c.PreScreeningCheckComponent),
      data: {
        [RouteData.FullScreenHeight]: true,
        [RouteData.Role]: [UserType.hcp],
      },
      canActivate: [
        (route: ActivatedRouteSnapshot) => {
          if (!route.queryParams['profile']) {
            inject(Router).navigate(['home']);
          }
          return Boolean(route.queryParams['profile']);
        },
      ],
    },
  ];
}
