import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import posthog from 'posthog-js';
import { tap } from 'rxjs';
import { CookieConsent } from '../cookie-consent/cookie-consent.interface';
import { CookieConsentService } from '../cookie-consent/cookie-consent.service';

@Injectable({
  providedIn: 'root',
})
export class PosthogInitializerService {
  private cookieConsentService = inject(CookieConsentService);
  private readonly destroyRef = inject(DestroyRef);

  /**
   * Subscribe to consent changes from CookieConsentService
   */
  updatePosthogConfigByConsent(): void {
    this.cookieConsentService.consent$.pipe(takeUntilDestroyed(this.destroyRef), tap(this.handleConsentChange.bind(this))).subscribe();
  }

  private handleConsentChange(consent: CookieConsent | null): void {
    const isConsentGiven = consent?.performance;
    if (isConsentGiven) {
      posthog.set_config({
        persistence: 'localStorage+cookie',
        disable_persistence: false,
      });

      posthog.opt_in_capturing();
      posthog.persistence?.save();
    } else {
      posthog.opt_out_capturing();
      posthog.set_config({
        persistence: 'memory',
        disable_persistence: true,
      });

      this.deletePHCookies();
    }
  }

  private deletePHCookies() {
    const cookies = document.cookie.split(';');
    const domains = ['.mytomorrows.com', '.posthog.com'];
    for (const cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      if (cookieName.startsWith('ph_')) {
        domains.forEach((domain) => {
          document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; Domain=${domain};`;
        });
      }
    }
  }
}
