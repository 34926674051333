import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@my-tomorrows/core-environment';
import { Observable } from 'rxjs';
import { AiPrescreeningHistoryApiParams, AiPrescreeningHistoryApiResponse } from './models';

@Injectable({ providedIn: 'root' })
export class PrescreeningApiService {
  readonly httpService = inject(HttpClient);
  readonly environment = inject(ENVIRONMENT);

  readonly url = this.environment.microservicesApiUrl;

  getPrescreeningHistory(params: AiPrescreeningHistoryApiParams): Observable<AiPrescreeningHistoryApiResponse> {
    return this.httpService.get<AiPrescreeningHistoryApiResponse>(`${this.url}/search/pre-screeners`, {
      params: {
        limit: params.limit,
        offset: params.offset || 0,
      },
      withCredentials: true,
    });
  }
}
